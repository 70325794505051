import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Typography,
  Grid,
  Paper,
  Tooltip,
  IconButton,
  Box
} from "@mui/material";
import { TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LaunchIcon from "@mui/icons-material/Launch";
import { path } from "../../apiCentral/path";

export default function CardDetail() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cardIdFromURL = queryParams.get("card_id");

  const [cardId, setCardId] = useState(cardIdFromURL || "");
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingFront, setLoadingFront] = useState(false);
  const [loadingBack, setLoadingBack] = useState(false);
  const [error, setError] = useState(null);
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);

  useEffect(() => {
    if (cardIdFromURL) {
      fetchCardDetails(cardIdFromURL);
    }
  }, [cardIdFromURL]);

  const fetchCardDetails = async (id) => {
    setLoading(true);
    setError(null);
    setData(null);
    setUserData(null);
    setFrontImage(null);
    setBackImage(null);

    try {
      const token = Cookies.get("token");
      const response = await axios.post(
        path.fetchCardDetails,
        { card_id: id },
        { headers: { Authorization: `${token}` } }
      );

      const cardData = response.data.data || {};
      const userData = response.data.user_details || {};

      setData(cardData);
      setUserData(userData);

      if (cardData.front_img_bucket) {
        setLoadingFront(true);
        const frontImg = await fetchImageAsBase64(cardData.front_img_bucket, token);
        setFrontImage(frontImg);
        setLoadingFront(false);
      }
      if (cardData.back_img_bucket) {
        setLoadingBack(true);
        const backImg = await fetchImageAsBase64(cardData.back_img_bucket, token);
        setBackImage(backImg);
        setLoadingBack(false);
      }
    } catch (err) {
      console.log(err);
      setError("Failed to fetch card details");
    } finally {
      setLoading(false);
    }
  };

  const fetchImageAsBase64 = async (url, authToken) => {
    try {
      const response = await axios.post(`https://cdn.habsyapp.com/${url}`, null, {
        headers: {
          Authorization: authToken,
        },
        responseType: "arraybuffer",
      });
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      return `data:image/jpeg;base64,${base64}`;
    } catch (error) {
      return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (cardId) {
      fetchCardDetails(cardId);

      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("card_id", cardId);
      window.history.replaceState(null, "", currentUrl.toString());
    }
  };

  const handleCopyClick = (value) => {
    navigator.clipboard.writeText(value || "");
  };

  // Render array fields in multiple rows (one entry per line) with a copy icon.
  const renderArrayField = (label, values) => (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography variant="subtitle2" gutterBottom>
        {label}
      </Typography>
      {Array.isArray(values) && values.length > 0 ? (
        values.map((val, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            sx={{ backgroundColor: "#f2f2f2", p: 1, borderRadius: "4px" }}
          >
            <Typography>{val}</Typography>
            <Box>
              <Tooltip title="Copy">
                <IconButton onClick={() => handleCopyClick(val)}>
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              {label === "Websites" && (
                <Tooltip title="Open in new tab">
                  <IconButton onClick={() => window.open(val, "_blank")}>
                    <LaunchIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ))
      ) : (
        <Typography color="textSecondary">No data available</Typography>
      )}
    </Paper>
  );

  // Render a single-value field in a Paper box with a copy icon.
  const renderSingleField = (label, value) => (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Typography variant="subtitle2" gutterBottom>
        {label}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "#f2f2f2", p: 1, borderRadius: "4px" }}
      >
        <Typography>{value || "N/A"}</Typography>
        <Tooltip title="Copy">
          <IconButton onClick={() => handleCopyClick(value)}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Paper>
  );

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Card Details
      </Typography>

      <form onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
        <TextField
          label="Enter Card ID"
          value={cardId}
          onChange={(e) => setCardId(e.target.value)}
          required
          style={{ marginRight: "10px", width: "300px" }}
        />
        <Button type="submit" variant="contained" color="primary">
          Fetch Details
        </Button>
      </form>

      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}

      {/* User Details Row */}
      {userData && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={4}>
            {renderSingleField("Email", userData.email)}
          </Grid>
          <Grid item xs={12} md={4}>
            {renderSingleField("Display Name", userData.display_name)}
          </Grid>
          <Grid item xs={12} md={4}>
            {renderSingleField("User Type", userData.user_type)}
          </Grid>
        </Grid>
      )}

      {data && (
        <>
          {/* Two columns per row for the main fields */}
          <Grid container spacing={2}>
            {/* Row 1: Name, Company Names */}
            <Grid item xs={12} md={6}>
              {renderSingleField("Name", data.person_name)}
            </Grid>
            <Grid item xs={12} md={6}>
              {renderArrayField("Company Names", data.company_names)}
            </Grid>

            {/* Row 2: Designations, Emails */}
            <Grid item xs={12} md={6}>
              {renderArrayField("Designations", data.designations)}
            </Grid>
            <Grid item xs={12} md={6}>
              {renderArrayField("Emails", data.emails)}
            </Grid>

            {/* Row 3: Phone Numbers, Websites */}
            <Grid item xs={12} md={6}>
              {renderArrayField("Phone Numbers", data.mobile_numbers)}
            </Grid>
            <Grid item xs={12} md={6}>
              {renderArrayField("Websites", data.websites)}
            </Grid>

            {/* Row 4: Address, Created At */}
            <Grid item xs={12} md={6}>
              {renderSingleField("Address", data.address)}
            </Grid>
            <Grid item xs={12} md={6}>
              {renderSingleField(
                "Created At",
                data.created_at ? new Date(data.created_at).toLocaleString() : ""
              )}
            </Grid>

            {/* Row 5: Updated At and Note */}
            <Grid item xs={12} md={6}>
              {renderSingleField(
                "Updated At",
                data.updated_at ? new Date(data.updated_at).toLocaleString() : ""
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {renderSingleField("Note", data.note)}
            </Grid>
          </Grid>

          {/* Images Section */}
          <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2, textAlign: "center" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Front Image
                </Typography>
                {loadingFront ? (
                  <CircularProgress />
                ) : frontImage ? (
                  <img
                    src={frontImage}
                    alt="Front"
                    style={{
                      width: "100%",
                      height: "auto",
                      maxWidth: "800px",
                      borderRadius: "4px"
                    }}
                  />
                ) : (
                  <Typography>No front image available</Typography>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper variant="outlined" sx={{ p: 2, textAlign: "center" }}>
                <Typography variant="subtitle2" gutterBottom>
                  Back Image
                </Typography>
                {loadingBack ? (
                  <CircularProgress />
                ) : backImage ? (
                  <img
                    src={backImage}
                    alt="Back"
                    style={{
                      width: "100%",
                      height: "auto",
                      maxWidth: "800px",
                      borderRadius: "4px"
                    }}
                  />
                ) : (
                  <Typography>No back image available</Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
